<template>
    <div id="Header" class="Header" ref="main">
        <div class="main">
            <Hero :stage="stage" :lock="lock" />

            <div class="row" :class="[stage, {'lock': lock}]">
                <h1 class="job-title" style="text-align: left">LEAD DEVELOPER</h1>
                <Socials id="Socials" />
            </div>
        </div>
        <div class="pictures" :style="{'clip-path': clip}"></div>
    </div>
</template>

<script>
import Hero from './Hero.vue';
import Socials from './Socials.vue';

export default {
    name: 'Header',
    components: {
        Hero,
        Socials
    },
    data() {
        return {
            fullTimer: null,
            lockTimer: null,
            shortTimer: null,
            stage: 'hidden',
            lock: false,
            clip: 'circle(0 at 0% 0%)'
        }
    },
    methods: {
        getLocation(event) {
            if (event.touches) {
                event.preventDefault();
            }

            const paths = event.composedPath();
            let onLink = false;
            for (let i = 0; i< paths.length; i++) {
                if (paths[i].id === 'Socials') {
                    onLink = true;
                }
                if (paths[i].id === 'Header') {
                    const clientX = event.touches ? event.touches[0].clientX : event.clientX;
                    const clientY = event.touches ? event.touches[0].clientY : event.clientY;

                    const offsetTop = window.pageYOffset || document.documentElement.scrollTop;
                    const x = (clientX / window.innerWidth) * 100;
                    const y = ( (clientY + offsetTop) / window.innerHeight) * 100;
                    this.clip = onLink ? `circle(2rem at ${x}% ${y}%)` : `circle(5rem at ${x}% ${y}%)`;
                    return;
                }
            }
            this.clip = `circle(0 at 0% 0%)`;
        }
    },
    mounted() {
        this.$refs.main.addEventListener('mousemove', this.getLocation);
        this.$refs.main.addEventListener('touchmove', this.getLocation);
        
        this.shortTimer = setTimeout( () => this.stage = 'short', 750 );
        this.fullTimer = setTimeout( () => this.stage = 'full', 2250 );
        this.lockTimer = setTimeout( () => this.lock = true, 4450 );
    },
    beforeUnmount() {
        this.$refs.main.removeEventListener('mousemove', this.getLocation);
        clearTimeout(this.shortTimer);
        clearTimeout(this.fullTimer);
        clearTimeout(this.lockTimer);
    }
}
</script>

<style lang="scss">
.Header {
    width: 100%;
    height: 100vh;

    display: flex;
    justify-content: space-between;
    align-items: center;

    background-color: #000;
    cursor: none;

    * {
        cursor: none;
    }

    .main {
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        z-index: 1;
    }

    .row {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        &.hidden,
        &.short {
            .job-title {
                opacity: 0;
                transform: translateY(3rem);
            }
            #Socials {
                .item {
                    opacity: 0;
                    transform: translateY(3rem);
                }
            }
        }

        &.full {
            &:not(&.lock) {
                .job-title {
                    opacity: 1;
                    transform: translateY(0);
                    transition: opacity 0.75s ease 0.5s, transform 0.75s ease 0.5s;
                }
                #Socials {
                    .item {
                        opacity: 1;
                        transform: translateY(0);
                        transition: opacity 0.75s ease, transform 0.75s ease;
                        &:nth-of-type(1) {
                            transition-delay: 0.7s;
                        }
                        &:nth-of-type(2) {
                            transition-delay: 0.85s;
                        }
                        &:nth-of-type(3) {
                            transition-delay: 1s;
                        }
                    }
                }
            }
        }
    }

    .job-title {
        // color: #333;
        color: #fff;
        font-size: 1.5rem;
        text-align: center;
        width: max-content;
        flex-shrink: 0;
        margin: 0;
        transition: transform 0.15s ease;
        margin: 1rem 0;

        &:hover {
            transform: rotate(10deg) scale(1.25);
        }
    }

    .pictures {
        width: 100%;
        height: 100%;
        background-image: url('../assets/hero/hero-next.jpg');
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        will-change: clip-path;
        overflow: hidden;
        filter: brightness(0.85);
        transition: clip-path 0.1s ease;
    }
}

@media screen and (min-width: 750px) {
    .Header {
        .row {
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
        }
        .job-title {
            margin: 0;
        }
    }
}

@media screen and (min-width: 500px) {
    html {
        font-size: 18px;
    }
}

@media screen and (min-width: 750px) {
    html {
        font-size: 20px;
    }
}

@media screen and (min-width: 1000px) {
    html {
        font-size: 22px;
    }
}

@media screen and (min-width: 1250px) {
    html {
        font-size: 26px;
    }
}

@media screen and (min-width: 1500px) {
    html {
        font-size: 30px;
    }
}

@media screen and (min-width: 1750px) {
    html {
        font-size: 32px;
    }
}

@media screen and (min-width: 2000px) {
    html {
        font-size: 34px;
    }
}

@media screen and (min-width: 2250px) {
    html {
        font-size: 36px;
    }
}

@media screen and (min-width: 2500px) {
    html {
        font-size: 38px;
    }
}

@media screen and (min-width: 2750px) {
    html {
        font-size: 42px;
    }
}

@media screen and (min-width: 3000px) {
    html {
        font-size: 46px;
    }
}

@media screen and (min-width: 3250px) {
    html {
        font-size: 50px;
    }
}

@media screen and (min-width: 3500px) {
    html {
        font-size: 54px;
    }
}

@media screen and (min-width: 3750px) {
    html {
        font-size: 58px;
    }
}

</style>