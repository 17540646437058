<template>
    <ul class="Socials">
        <li class="item">
            <a class="link" href="https://www.linkedin.com/in/lawrence-burden-00175695/">
                <img class="icon" src="../assets/socials/linkedin.png" />
            </a>
        </li>
        <li class="item">
            <a class="link" href="https://github.com/LBurden">
                <img class="icon" src="../assets/socials/github.png" />
            </a>
        </li>
        <li class="item">
            <a class="link" href="https://www.instagram.com/lawrence_burden/">
                <img class="icon" src="../assets/socials/instagram.png" />
            </a>
        </li>
    </ul>
</template>

<script>
export default {
    name: 'Socials',
    data() {
        return {}
    },
    methods: {}
}
</script>

<style lang="scss">
.Socials {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0;
    margin: 0;

    .item {
        width: 2rem;
        margin: 0 0.25rem;
        list-style-type: none;
        transition: transform 0.2s ease;

        &:last-child {
            margin-right: 0;
        }

        &:hover {
            transform: scale(1.2) rotate(10deg);
        }

        .link {
            display: block;
            width: 100%;

            .icon {
                width: 100%;
            }
        }
    }
}
</style>