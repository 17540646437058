<template>
    <div class="Hero" :class="[ stage, {'lock': lock } ]">

        <h1 class="hero-letter top">
            <span>L</span>
        </h1>
        <h1 class="hero-word top">
            <span>A</span>
            <span>W</span>
            <span>R</span>
            <span>E</span>
            <span>N</span>
            <span>C</span>
            <span>E</span>
        </h1>
        <h1 class="hero-letter bottom">
            <span>B</span>
        </h1>
        <h1 class="hero-word bottom">
            <span>U</span>
            <span>R</span>
            <span>D</span>
            <span>E</span>
            <span>N</span>
        </h1>
        <div class="divider vertical"></div>
        <div class="divider horizontal"></div>
    </div>
</template>

<script>
export default {
    name: 'Hero',
    props: {
        stage: String,
        lock: Boolean
    },
    data() {
        return {}
    },
    methods: {}
}
</script>

<style lang="scss">
.Hero {
    width: 19em;
    height: 12em;
    position: relative;
    margin: 0 auto;
    font-family: 'Belleza', sans-serif;
    font-size: 12px;
    color: #fff;
    position: sticky;
    top: 10px;

    &.hidden {
        .hero-letter {
            &.top {
                span {
                    transform: translateX(100%);
                }
            }
            &.bottom {
                span {
                    transform: translateX(-100%);
                }
            }
        }
        .hero-word {
            opacity: 0;
        }
        
        .divider {
            &.vertical {
                transform: scaleY(0);
            }
            &.horizontal {
                opacity: 0;
            }
        }
    }

    &.short {
        .hero-letter {
            &.top,
            &.bottom {
                span {
                    transform: translateX(0);
                    transition: transform 0.5s ease 0.5s;
                }
            }
        }
        .hero-word {
            opacity: 0;

            &.top {
                span {
                    transform: translateY(-100%);
                }
            }

            &.bottom {
                span {
                    transform: translateY(100%);
                }
            }

            span {
                opacity: 0;
            }
        }

        .divider {
            transition: transform 0.5s ease-in;

            &.vertical {
                transform: scaleY(1);
                transform-origin: top;
            }
            &.horizontal {
                opacity: 0;
                transform: scaleX(0);
                transform-origin: left;
            }
        }
    }

    &.full {
        .hero-letter {
            transition: transform 0.5s ease-in;

            &.top {
                transform: translateY(-0.5em);
            }
            &.bottom {
                transform: translateY(0.5em);
            }
        }
        .hero-word {
            opacity: 1;

            span {
                opacity: 1;
                transform: translateY(0);
                transition: transform 0.5s ease 0.25s, opacity 0.5s ease 0.25s;

                @for $i from 1 through 7 {
                    &:nth-child(#{$i}) {
                        transition-delay: calc($i * 0.1s);
                    }
                }
            }
        }

        .divider {
            &.vertical {
                transform: scaleY(0);
                transform-origin: center;
                transition: transform 0.5s ease-in;
            }
            &.horizontal {
                transform: scaleX(1);
                transform-origin: left;
                transition: transform 0.5s ease-in 0.5s;
            }
        }
    }

    &.lock {
        .hero-letter {
            overflow: initial;

            span {
                transition: transform 1s ease-in;
                transition-delay: 0s !important;

                &:hover {
                    transform: scale(1.25) rotate(10deg) translateY(-0.1em);
                    transition: transform 0.05s ease-in;
                }
            }
        }
        .hero-word {
            span {
                transition: transform 1s ease-in;
                transition-delay: 0s !important;

                &:hover {
                    transform: scale(1.25) rotate(10deg) translateY(-0.1em);
                    transition: transform 0.05s ease-in;
                }
            }
        }
    }

    .hero-letter {
        position: absolute;
        top: calc(50% - 0.5em);
        font-size: 5em;
        line-height: 1em;
        margin: 0;
        overflow: hidden;

        &.top {
            left: 0;
        }

        &.bottom {
            left: 0.75em;
        }

        span {
            display: inline-block;
        }
    }

    .hero-word {
        position: absolute;
        display: flex;
        margin: 0;
        font-size: 3.75em;
        line-height: 1.33333em;

        &.top {
            left: 0.4666em;
            top: calc(50% - 1.4em);
        }

        &.bottom {
            left: 1.8em;
            top: calc(50% + 0.06666em);
        }

        span {
            display: inline-block;
        }
    }

    .divider {
        position: absolute;
        left: 3.175em;
        // background-color: #000;
        background-color: #fff;

        &.vertical {
            top: calc(50% - 1.9em);
            width: 1px;
            height: 4em;
        }

        &.horizontal {
            top: calc(50% - 0.5px);
            width: 15.5em;
            height: 1px;
        }
    }

    .button-group {
        position: fixed;
        bottom: 0;
        left: 0;
        width: 100%;
        display: flex;
        justify-items: center;
    }

}

@media screen and (min-width: 500px) {
    .Hero {
        font-size: 18px;
    }
}

@media screen and (min-width: 500px) {
    .Hero {
        font-size: 18px;
    }
}

@media screen and (min-width: 725px) {
    .Hero {
        font-size: 24px;
    }
}

@media screen and (min-width: 1000px) {
    .Hero {
        font-size: 30px;
    }
}

@media screen and (min-width: 1400px) {
    .Hero {
        font-size: 36px;
    }
}

@media screen and (min-width: 1900px) {
    .Hero {
        font-size: 40px;
    }
}

@media screen and (min-width: 2300px) {
    .Hero {
        font-size: 46px;
    }
}

@media screen and (min-width: 2700px) {
    .Hero {
        font-size: 54px;
    }
}

@media screen and (min-width: 3100px) {
    .Hero {
        font-size: 60px;
    }
}

@media screen and (min-width: 3400px) {
    .Hero {
        font-size: 68px;
    }
}

@media screen and (min-width: 3800px) {
    .Hero {
        font-size: 82px;
    }
}
</style>