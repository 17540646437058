<template>
    <div class="Home">
        <Header />
    </div>
</template>

<script>
import Header from '@/components/Header';

export default {
    name: 'Home',
    components: {
        Header
    }
}
</script>

<style lang="scss">
.Home {
    width: 100%;

}

</style>