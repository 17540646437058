<template>
    <router-view/>
</template>

<style lang="scss">
*,
*:before,
*:after {
    box-sizing: border-box;
    user-select: none;
}

html,
body {
    width: 100%;
    padding: 0;
    margin: 0;
}

body {
    background-color: #cccccc;
}

#app {
    width: 100%;
}

html,
button,
input,
select,
textarea {

    font-family: 'Belleza', sans-serif;
    // font-family: 'Cinzel Decorative', cursive;
    // font-family: 'Italiana', serif;


    // font-family: 'Staatliches', cursive;
    font-size: 1rem;
    -webkit-font-smoothing: antialiased;
}

</style>
